import { WizardStepKey } from '@voice/utils';

export const NETSAPIENS_MODULES = {
  domains: {
    wizardKey: WizardStepKey.domain,
    title: 'Domain',
    icon: 'pi pi-building',
  },
  numbers: {
    wizardKey: WizardStepKey.numbers,
    title: 'Phone Numbers',
    icon: 'pi pi-phone',
  },
  sites: {
    wizardKey: WizardStepKey.sites,
    title: 'Sites',
    icon: 'pi pi-map-marker',
  },
  users: {
    wizardKey: WizardStepKey.users,
    title: 'Users',
    icon: 'pi pi-users',
  },
  sipTrunks: {
    wizardKey: 'sipTrunks',
    title: 'SIP Trunks',
    icon: 'pi pi-server',
  },
  attendants: {
    wizardKey: 'attendants',
    title: 'Auto Attendants',
    icon: 'pi pi-sitemap',
  },
};

export const WIZARD_STEPPER_ITEMS = {
  domain: NETSAPIENS_MODULES.domains,
  numbers: NETSAPIENS_MODULES.numbers,
  sites: NETSAPIENS_MODULES.sites,
  users: NETSAPIENS_MODULES.users,
  devices: {
    wizardKey: WizardStepKey.devices,
    title: 'Inventory',
    icon: 'pi pi-list',
  },
  attendants: {
    wizardKey: WizardStepKey.attendants,
    title: 'Auto Attendants',
    icon: 'pi pi-sitemap',
  },
  verify: {
    wizardKey: WizardStepKey.verify,
    title: 'User-Phone Assignment',
    icon: 'pi pi-check-circle',
  },
  provision: {
    wizardKey: WizardStepKey.provision,
    title: 'Provision',
    icon: 'pi pi-send',
  },
};
